import axios from "axios";
import { showErrorNotification } from "@/utils/errors";
import { Promise } from "core-js";

export default {
  namespaced: true,
  state: {
    item: null,
    list: null,
    dashboardList: null,
  },
  getters: {
    getActivity: (state) => state.item,
    getList: (state) => state.list,
    getDashboardList: (state) => state.dashboardList,
    getDetails: (state) => state.details,
  },
  mutations: {
    SET_ACTIVITY(state, item) {
      state.item = item;
    },
    SET_LIST(state, list) {
      state.list = list;
    },
    SET_DASHBOARD_LIST(state, list) {
      state.dashboardList = list;
    },
  },
  actions: {
    loadList({ commit, rootGetters, dispatch }) {
      const user = rootGetters["user/getUser"];
      return new Promise((resolve, reject) => {
        axios
          .get(process.env.VUE_APP_SERVER_URL + "/vehicle/timeline/entry", {
            params: {
              vehicle_id: user.active_vehicle_uuid,
            },
          })
          .then((res) => {
            commit("SET_LIST", res.data);
            resolve(res.data);
          })
          .catch((err) => {
            showErrorNotification(dispatch, err);
            reject();
          });
      });
    },
    loadFilteredList({ commit, rootGetters, dispatch }, filter) {
      const user = rootGetters["user/getUser"];
      return new Promise((resolve, reject) => {
        axios
          .get(process.env.VUE_APP_SERVER_URL + "/vehicle/timeline/entry", {
            params: {
              vehicle_id: user.active_vehicle_uuid,
              ...filter,
            },
          })
          .then((res) => {
            commit("SET_LIST", res.data);
            resolve(res.data);
          })
          .catch((err) => {
            showErrorNotification(dispatch, err);
            reject();
          });
      });
    },
    loadDashboardList({ commit, dispatch }, carId) {
      axios
        .get(
          process.env.VUE_APP_SERVER_URL + "/vehicle/timeline/entry/dashboard",
          {
            params: {
              vehicle_id: carId,
            },
          }
        )
        .then((res) => {
          commit("SET_DASHBOARD_LIST", res.data);
        })
        .catch((err) => {
          showErrorNotification(dispatch, err);
        });
    },
    load({ commit, rootGetters, dispatch }, id) {
      const user = rootGetters["user/getUser"];
      return new Promise((resolve, reject) => {
        axios
          .get(
            process.env.VUE_APP_SERVER_URL + `/vehicle/timeline/entry/${id}`,
            {
              params: {
                vehicle_id: user.active_vehicle_uuid,
              },
            }
          )
          .then((res) => {
            commit("SET_ACTIVITY", res.data);
            resolve(res.data);
          })
          .catch((err) => {
            showErrorNotification(dispatch, err);
            reject();
          });
      });
    },
    update({ commit, dispatch, rootGetters }, activity) {
      const user = rootGetters["user/getUser"];
      return new Promise((resolve, reject) => {
        axios
          .patch(process.env.VUE_APP_SERVER_URL + "/vehicle/timeline/entry", {
            vehicle_id: user.active_vehicle_uuid,
            ...activity,
          })
          .then((res) => {
            commit("SET_ACTIVITY", res.data);
            resolve(res.data);
          })
          .catch((err) => {
            showErrorNotification(dispatch, err);
            reject();
          });
      });
    },
    create({ commit, dispatch, rootGetters }, activity) {
      const user = rootGetters["user/getUser"];
      return new Promise((resolve, reject) => {
        axios
          .post(process.env.VUE_APP_SERVER_URL + "/vehicle/timeline/entry", {
            vehicle_id: user.active_vehicle_uuid,
            ...activity,
          })
          .then((res) => {
            commit("SET_ACTIVITY", res.data);
            resolve(res.data);
          })
          .catch((err) => {
            showErrorNotification(dispatch, err);
            reject();
          });
      });
    },
    ocrReceipt({ commit, dispatch, rootGetters }, data) {
      const user = rootGetters["user/getUser"];
      return new Promise((resolve, reject) => {
        axios
          .post(
            process.env.VUE_APP_SERVER_URL + "/vehicle/timeline/entry/ocr",
            {
              vehicle_id: user.active_vehicle_uuid,
              media: data.media,
              type: "refuel",
              mileage: data.mileage,
            }
          )
          .then((res) => {
            commit("SET_ACTIVITY", res.data);
            resolve(res.data);
          })
          .catch((err) => {
            showErrorNotification(dispatch, err);
            reject();
          });
      });
    },
    ocrMileage({ commit, dispatch, rootGetters }, data) {
      const user = rootGetters["user/getUser"];
      return new Promise((resolve, reject) => {
        axios
          .post(
            process.env.VUE_APP_SERVER_URL + "/vehicle/timeline/entry/ocr",
            {
              vehicle_id: user.active_vehicle_uuid,
              media: data.media,
              type: "mileage",
            }
          )
          .then((res) => {
            commit("SET_ACTIVITY", res.data);
            resolve(res.data);
          })
          .catch((err) => {
            showErrorNotification(dispatch, err);
            reject();
          });
      });
    },
    delete({ commit, dispatch, rootGetters }, activity) {
      const user = rootGetters["user/getUser"];
      return new Promise((resolve, reject) => {
        axios
          .delete(
            process.env.VUE_APP_SERVER_URL +
              `/vehicle/timeline/entry/${activity.uuid}`,
            {
              params: {
                vehicle_id: user.active_vehicle_uuid,
              },
            }
          )
          .then((res) => {
            commit("SET_ACTIVITY", null);
            resolve(res.data);
          })
          .catch((err) => {
            showErrorNotification(dispatch, err);
            reject();
          });
      });
    },
    setActivity({ commit }, item) {
      commit("SET_ACTIVITY", item);
    },
    identifyMileage({ commit }, image) {
      commit("SET_ACTIVITY", null);
      return new Promise((resolve, reject) => {
        axios
          .post(process.env.VUE_APP_SERVER_URL + "/ocr/mileage", {
            image: image.replace("data:image/png;base64,", ""),
          })
          .then((res) => {
            const data = res.data;
            resolve(data.mileage);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },
  },
};
