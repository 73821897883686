import axios from "axios";
import { Geolocation } from "@capacitor/geolocation";
import { latLng } from "leaflet";

export default {
  namespaced: true,
  state: {
    gasStations: [],
    gasStation: null,
    position: null,
    settings: JSON.parse(localStorage.getItem("gas_station.settings")) || {
      fuelType: "e5",
      sortBy: "distance",
      radius: 10,
      open_only: false,
      fav: false,
      brands: [],
    },
  },
  getters: {
    getGasStations: (state) => state.gasStations,
    getGasStation: (state) => state.gasStation,
    getPosition: (state) => state.position,
    getSettings: (state) => state.settings,
    getSetting: (state) => (key) => {
      return state.settings[key] ? state.settings[key] : null;
    },
  },
  mutations: {
    SET_GAS_STATIONS(state, payload) {
      state.gasStations = payload;
    },
    SET_GAS_STATION(state, payload) {
      state.gasStation = payload;
    },
    SET_POSITION(state, position) {
      state.position = position;
    },
    SET_SETTINGS(state, settings) {
      state.settings = settings;
      localStorage.setItem(
        "gas_station.settings",
        JSON.stringify(state.settings)
      );
    },
    RESET_SETTINGS(state) {
      state.settings = {
        fuelType: "e5",
        sortBy: "distance",
        radius: 10,
        open_only: false,
        fav: false,
        brands: [],
      };
      localStorage.setItem(
        "gas_station.settings",
        JSON.stringify(state.settings)
      );
    },
  },
  actions: {
    setSetting({ commit, getters }, parameter) {
      if (!parameter.key) {
        return;
      }

      let settings = getters.getSettings;
      settings[parameter.key] = parameter.value;

      commit("SET_SETTINGS", settings);
    },
    loadGasStations({ commit, dispatch, state }, parameter) {
      return new Promise((resolve, reject) => {
        axios
          .get(process.env.VUE_APP_SERVER_URL + "/petrol/station", {
            params: {
              lat: parameter.location.lat,
              lng: parameter.location.lng,
              radius: state.settings.radius,
              open_only: state.settings.open_only,
              brands: Array.isArray(state.settings.brands)
                ? state.settings.brands.join(",")
                : "",
              fuel_type: state.settings.fuelType,
              fav: state.settings.fav,
              sort_by: state.settings.sortBy,
            },
          })
          .then((res) => {
            commit("SET_GAS_STATIONS", res.data);
            resolve(res.data);
          })
          .catch((err) => {
            dispatch(
              "notifications/addNotification",
              {
                message: err.response.data.error,
                type: "error",
              },
              { root: true }
            );
            reject(err);
          });
      });
    },
    loadGasStation({ commit, dispatch }, station_id) {
      return new Promise((resolve, reject) => {
        axios
          .get(process.env.VUE_APP_SERVER_URL + `/petrol/station/${station_id}`)
          .then((res) => {
            commit("SET_GAS_STATION", res.data);
            resolve();
          })
          .catch((err) => {
            dispatch(
              "notifications/addNotification",
              {
                message: err.response.data.error,
                type: "error",
              },
              { root: true }
            );
            reject(err);
          });
      });
    },
    setPosition({ commit }, position) {
      commit("SET_POSITION", position);
    },
    setSettings({ commit }, settings) {
      commit("SET_SETTINGS", settings);
    },
    hasPositionPermission() {
      return new Promise((resolve, reject) => {
        Geolocation.checkPermissions()
          .then((res) => {
            if (res.location === "denied") {
              resolve(false);
            } else {
              resolve(true);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    updatePosition({ commit, dispatch }) {
      return new Promise((resolve, reject) => {
        try {
          Geolocation.getCurrentPosition()
            .then((location) => {
              commit(
                "SET_POSITION",
                latLng(location.coords.latitude, location.coords.longitude)
              );
              resolve(location);
            })
            .catch((err) => {
              if (err.code === 1) {
                Geolocation.requestPermissions().then((location) => {
                  commit(
                    "SET_POSITION",
                    latLng(location.coords.latitude, location.coords.longitude)
                  );
                  resolve(location);
                });
              }
              dispatch(
                "notifications/addNotification",
                {
                  message: "notifications.error.position",
                  type: "error",
                },
                { root: true }
              );
              reject(err);
            });
        } catch (err) {
          if (err.code === 1) {
            Geolocation.requestPermissions();
          }
        }
      });
    },
    resetSettings({ commit }) {
      commit("RESET_SETTINGS");
    },
  },
};
