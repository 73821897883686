<template>
  <LoadingBar class="bar" v-if="isLoading" />
  <div class="updateNotification" v-if="updateExists">
    <div class="updateNotification__img-container">
      <inline-svg
        class="updateNotification__icon"
        :src="require('@/assets/update.svg')"
        aria-label="Check"
      ></inline-svg>
    </div>
    <div class="updateNotification__content">
      <div class="heading">{{ $t("core.notifications.update.title") }}</div>
      <div class="text">{{ $t("core.notifications.update.text") }}</div>
      <Button @click="refreshApp">{{
        $t("core.notifications.update.btn")
      }}</Button>
    </div>
  </div>
  <router-view />
  <div
    class="notification__wrapper"
    v-if="notifications && notifications.length > 0"
  >
    <Notification
      :class="getClasses(index)"
      v-for="(note, index) in notifications"
      :key="index"
      :notification="note"
      :note-index="index"
    />
  </div>
  <NavigationBar v-if="showNavigationBar" />
</template>

<script>
import axios from "axios";
import { gsap } from "gsap";
import Cookies from "js-cookie";
import LoadingBar from "@/components/ui/LoadingBar";
import Notification from "@/modules/core/components/ui/Notification";
import NavigationBar from "@/modules/core/components/NavigationBarMvp";
import Button from "@/components/ui/Button";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    Notification,
    Button,
    LoadingBar,
    NavigationBar,
  },
  data: () => ({
    showNotes: false,
    registration: null,
    refreshing: false,
    updateExists: false,
  }),
  computed: {
    ...mapGetters("user", {
      accessToken: "getAccessToken",
      language: "getLanguage",
    }),
    ...mapGetters("notifications", {
      notifications: "getNotifications",
    }),
    ...mapGetters("loading", {
      isLoading: "getIsLoading",
    }),
    isOnline() {
      return window.navigator.onLine;
    },
    showNavigationBar() {
      return this.$route.meta.navbar === false ? false : true;
    },
  },
  watch: {
    notifications() {
      if (!this.showNotes) {
        this.showNotes;
        var tl = gsap.timeline();

        tl.from(`.notification__item--${this.notifications.length - 1}`, {
          y: 16,
          opacity: 0,
          duration: 0.5,
        });

        setTimeout(() => {
          this.showNotes = false;
        }, 8000);
      } else {
        var tr = gsap.timeline();

        tr.from(`.notification__item--${this.notifications.length - 1}`, {
          y: 16,
          opacity: 0,
          duration: 0.5,
        });
      }
    },
    $route() {
      window.scrollTo(0, 0);
    },
    isOnline() {
      this.addNotification({
        message: this.isOnline ? "Online" : "Offline",
        type: "offline",
      });
    },
    language() {
      this.$root.$i18n.locale = this.language;
    },
  },
  created() {
    document.addEventListener("swUpdated", this.updateAvailable, {
      once: true,
    });

    if (navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener("controllerchange", () => {
        if (this.refreshing) return;
        this.refreshing = true;
        window.location.reload();
      });
    }

    const refreshUrl = process.env.VUE_APP_SERVER_URL + "/auth/refresh";

    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error) => {
        /* refresh token and retry request once more on 401
         else log user out
      */
        const { config: originalReq, response } = error;

        // skip refresh token request, retry attempts to avoid infinite loops
        if (
          originalReq.url !== refreshUrl &&
          !originalReq.isRetryAttempt &&
          response &&
          response.status === 401 &&
          this.$route.name !== "Login"
        ) {
          try {
            await this.refresh();
            originalReq.isRetryAttempt = true;
            originalReq.headers["Authorization"] = `Bearer ${this.accessToken}`;
            axios.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${this.accessToken}`;
            return await axios.request(originalReq);
          } catch (err) {
            // log user out if fail to refresh (due to expired or missing token) or persistent 401 errors from original requests
            if (err.response && err.response.status === 401) {
              this.$router.push({ name: "LoginChoice" });
            }
            // suppress original error to throw the new one to get new information
            throw err;
          }
        } else {
          throw error;
        }
      }
    );

    if (this.accessToken) {
      this.initialize();
    }
  },
  mounted() {
    const urlParams = new URLSearchParams(window.location.search);
    const referral = urlParams.get("ref");

    if (referral) {
      Cookies.remove("referral");
      Cookies.set("referral", referral);
      this.setReferral(referral);
    }

    if (this.notifications.length > 0) {
      this.showNotes = true;
      this.length = this.notifications.length;
      var tl = gsap.timeline();

      tl.from(".notification__item", {
        y: 16,
        opacity: 0,
        duration: 0.5,
        stagger: 0.3,
      });
    }
  },
  methods: {
    ...mapActions("user", ["refresh", "initialize", "logout", "setReferral"]),
    ...mapActions("notifications", ["addNotification"]),
    getClasses(index) {
      return ["notification__item", `notification__item--${index}`];
    },
    updateAvailable(event) {
      this.registration = event.detail;
      this.updateExists = true;
    },
    refreshApp() {
      this.updateExists = false;
      if (!this.registration || !this.registration.waiting) {
        return;
      }
      this.registration.waiting.postMessage({ type: "SKIP_WAITING" });
    },
  },
};
</script>

<style lang="scss">
body {
  min-height: 100vh;
}

#app {
  font-family: "WorkSans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  .bar {
    position: fixed;
    top: 0;
    z-index: 1000;
  }

  .updateNotification {
    @apply bg-vi-dark-blue flex flex-col text-white p-8 fixed;
    justify-content: center;
    top: 0;
    left: 0;
    z-index: 10000;
    width: 100vw;
    height: 100vh;

    &__img-container {
    }

    &__icon {
      width: 100%;
    }

    &__content {
      @apply text-center;

      > .heading {
        @apply text-2xl font-bold;
      }

      > .text {
        @apply text-lg mt-4;
      }

      > .btn {
        @apply mt-4;
      }
    }
  }

  .notification__wrapper {
    z-index: 1000;
    position: fixed;
    bottom: 0;
    padding: 0 16px;
    margin-bottom: 80px;
    width: 100%;
  }

  .notification__item:not(:last-of-type) {
    margin-bottom: 12px;
  }
}
</style>
