export const gasStationRoutes = [
  {
    path: "/gas-station",
    name: "GasStation",
    component: () => import("./views/GasStationOverview.vue"),
    meta: { requiresAuth: false },
  },
  {
    path: "/gas-station/:id",
    name: "GasStationDetail",
    component: () => import("./views/GasStationDetail.vue"),
    meta: { requiresAuth: false },
  },
];
