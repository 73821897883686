import axios from "axios";
import Cookies from "js-cookie";
import router from "@/router/index";

export default {
  namespaced: true,
  state: {
    status: "",
    accessToken: Cookies.get("accessToken") || "",
    tokenExpired: null,
    referral: Cookies.get("referral") || "",
    user: /*JSON.parse(localStorage.getItem("user")) ||*/ null,
    profile: {},
    email: "",
    language: localStorage.getItem("language"),
  },
  getters: {
    getUser: (state) => state.user,
    getProfile: (state) => state.profile,
    getAccessToken: (state) => state.accessToken,
    isTokenExpired: (state) => state.tokenExpired,
    getAuthStatus: (state) => state.status,
    getEmail: (state) => state.email,
    getLanguage: (state) => state.language,
    getReferral: (state) => state.referral,
    isLoggedIn: (state) => state.accessToken != "" && state.user !== null,
  },
  mutations: {
    AUTH_REQUEST(state) {
      state.status = "loading";
    },
    AUTH_SUCCESS(state, { access }) {
      state.status = "success";
      state.accessToken = access;
    },
    AUTH_ERROR(state) {
      state.status = "error";
    },
    INITIALIZE(state, user) {
      state.user = user;
      state.accessToken = Cookies.get("accessToken") || "";
    },
    SET_PROFILE(state, profile) {
      state.profile = profile;
    },
    SET_EMAIL(state, email) {
      state.email = email;
    },
    LOGOUT(state) {
      state.status = "";
      state.accessToken = "";
      state.user = {};
      state.profile = {};
      state.messages = [];
      state.email = "";
      state.referral = "";
      state.language = "";
    },
    UPDATE_TOKEN(state, { access }) {
      state.status = "success";
      state.accessToken = access;
    },
    SET_USER(state, user) {
      state.user = user;
    },
    SET_MESSAGES(state, payload) {
      state.messages = payload;
    },
    SET_SINGLE_MESSAGE(state, payload) {
      state.singleMessage = payload;
    },
    SET_LANGUAGE(state, payload) {
      state.language = payload;
    },
    SET_REFERRAL(state, referral) {
      state.referral = referral;
    },
    SET_TOKEN_EXPIRED(state, tokenExpired) {
      state.tokenExpired = tokenExpired;
    },
  },
  actions: {
    resendValidationEmail({ dispatch }, email) {
      axios
        .get(process.env.VUE_APP_SERVER_URL + "/user/verify", email)
        .then((res) => {
          if (res.status === 204) {
            dispatch(
              "notifications/addNotification",
              {
                message: "notifications.success.email_resend",
                type: "success",
              },
              { root: true }
            );
          }

          if (res.status === 500) {
            dispatch(
              "notifications/addNotification",
              {
                message: "notifications.error.email_resend",
                type: "error",
              },
              { root: true }
            );
          }
        })
        .catch(() => {
          dispatch(
            "notifications/addNotification",
            {
              message: "notifications.error.email_resend",
              type: "error",
            },
            { root: true }
          );
        });
    },
    verifyEmail({ dispatch }, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(process.env.VUE_APP_SERVER_URL + "/user/verify", {
            id: data.userId,
            hash: data.hash,
          })
          .then((res) => {
            if (res.status === 204) {
              dispatch(
                "notifications/addNotification",
                {
                  message: "notifications.success.email_verified",
                  type: "success",
                },
                { root: true }
              );
              resolve(res);
            }

            if (res.status === 403) {
              dispatch(
                "notifications/addNotification",
                {
                  message: "notifications.error.email_verify",
                  type: "error",
                },
                { root: true }
              );
              reject(res);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    login({ commit, dispatch }, user) {
      return new Promise((resolve, reject) => {
        commit("AUTH_REQUEST");
        axios
          .post(process.env.VUE_APP_SERVER_URL + "/auth/login", user)
          .then((res) => {
            const access = res.data.access_token;
            Cookies.set("accessToken", access, { expires: 7 });
            axios.defaults.headers.common["Authorization"] = `Bearer ${access}`;
            commit("AUTH_SUCCESS", { access });
            resolve(res);
          })
          .catch((err) => {
            console.log(err);
            dispatch(
              "notifications/addNotification",
              {
                message: `user.notification.${err.response.data.error}`,
                type: "error",
              },
              { root: true }
            );
            commit("AUTH_ERROR");
            reject(err);
          });
      });
    },
    socialLogin({ commit, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        commit("AUTH_REQUEST");
        axios
          .post(process.env.VUE_APP_SERVER_URL + "/auth/social", {
            provider: payload.provider,
            access_token: payload.accessToken,
            user: payload.user ? payload.user : null,
          })
          .then((res) => {
            const access = res.data.access_token;
            Cookies.set("accessToken", access, { expires: 7 });
            axios.defaults.headers.common["Authorization"] = `Bearer ${access}`;

            commit("AUTH_SUCCESS", { access });
            resolve(res);
          })
          .catch((err) => {
            dispatch(
              "notifications/addNotification",
              {
                message: err.response.data.error,
                type: "error",
              },
              { root: true }
            );
            commit("AUTH_ERROR");
            reject(err);
          });
      });
    },
    register({ commit, dispatch }, user) {
      return new Promise((resolve, reject) => {
        commit("AUTH_REQUEST");
        axios
          .post(process.env.VUE_APP_SERVER_URL + "/auth/register", user)
          .then((res) => {
            commit("INITIALIZE", res);
            resolve(res);
          })
          .catch((err) => {
            dispatch(
              "notifications/addNotification",
              {
                message: err.response.data.error,
                type: "error",
              },
              { root: true }
            );
            commit("AUTH_ERROR", err);
            reject(err);
          });
      });
    },
    initialize({ commit, dispatch }) {
      const access = Cookies.get("accessToken");
      return new Promise((resolve, reject) => {
        if (access) {
          dispatch("loading/setLoading", true, { root: true });
          axios.defaults.headers.common["Authorization"] = `Bearer ${access}`;
          axios
            .get(process.env.VUE_APP_SERVER_URL + "/user")
            .then((res) => {
              commit("INITIALIZE", res.data);
              localStorage.setItem("user", JSON.stringify(res.data));

              if (res.data.active_vehicle_uuid) {
                dispatch("car/loadMainCar", res.data.active_vehicle_uuid, {
                  root: true,
                });
              } else {
                let user = res.data;
                dispatch("car/loadVehicles", {}, { root: true }).then((res) => {
                  if (res[0]) {
                    user.active_vehicle_uuid = res[0].uuid;
                    dispatch("car/loadMainCar", user.active_vehicle_uuid, {
                      root: true,
                    });
                  }
                });
              }

              dispatch("loading/setLoading", false, { root: true });
              resolve();
            })
            .catch((err) => {
              dispatch("loading/setLoading", false, { root: true });
              console.log(err);
              if (err.response.status == 401) {
                dispatch(
                  "notifications/addNotification",
                  {
                    message: "user.notification.token_invalid",
                    type: "error",
                  },
                  { root: true }
                );
              } else {
                dispatch(
                  "notifications/addNotification",
                  {
                    message: err.response.data.error,
                    type: "error",
                  },
                  { root: true }
                );
              }

              commit("AUTH_ERROR", err);
              reject(err);
            });
        } else {
          resolve();
        }
      });
    },
    updateUser({ commit, dispatch }, user) {
      return new Promise((resolve, reject) => {
        axios
          .patch(process.env.VUE_APP_SERVER_URL + "/user", user)
          .then((res) => {
            localStorage.setItem("user", JSON.stringify(res.data));
            commit("SET_USER", res.data);
            resolve(res.data);
          })
          .catch((err) => {
            dispatch(
              "notifications/addNotification",
              {
                message: err.response.data.error,
                type: "error",
              },
              { root: true }
            );
            commit("AUTH_ERROR", err);
            console.log(err);
            reject(err);
          });
      });
    },
    refresh({ commit, dispatch }) {
      if (this.isLoggedIn) {
        return new Promise((resolve, reject) => {
          commit("AUTH_REQUEST");

          axios
            .get(process.env.VUE_APP_SERVER_URL + "/auth/refresh")
            .then((res) => {
              const access = res.data.access_token;

              Cookies.set("accessToken", access, { expires: 7 });
              axios.defaults.headers.common[
                "Authorization"
              ] = `Bearer ${access}`;

              commit("UPDATE_TOKEN", { access });
              resolve(res);
            })
            .catch((err) => {
              dispatch(
                "notifications/addNotification",
                {
                  message: "Not refreshed",
                  type: "error",
                },
                { root: true }
              );
              Cookies.remove("accessToken");
              dispatch("clear");
              axios.defaults.headers.common["Authorization"] = "";

              if (router.currentRoute._value.name !== "Login") {
                router.push({
                  name: "LoginChoice",
                });
              }

              reject(err);
            });
        });
      }
    },
    logout({ commit, dispatch }) {
      commit("AUTH_REQUEST");
      axios
        .get(process.env.VUE_APP_SERVER_URL + "/auth/logout")
        .then(() => {
          Cookies.remove("accessToken");
          axios.defaults.headers.common["Authorization"] = "";
          dispatch("clear");
          localStorage.clear();
          router.go();
        })
        .catch((err) => {
          Cookies.remove("accessToken");
          console.log(err);
          dispatch("clear");
          localStorage.clear();
          router.go();
          axios.defaults.headers.common["Authorization"] = "";
        });
    },
    delete({ dispatch }) {
      axios
        .delete(process.env.VUE_APP_SERVER_URL + "/user")
        .then(() => {
          Cookies.remove("accessToken");
          axios.defaults.headers.common["Authorization"] = "";
          dispatch("clear");
          localStorage.clear();
          router.push({
            name: "LoginChoice",
          });
        })
        .catch((err) => {
          dispatch(
            "notifications/addNotification",
            {
              message: err.response.data.error,
              type: "error",
            },
            { root: true }
          );
        });
    },
    passwordReset({ dispatch }, email) {
      return new Promise((resolve, reject) => {
        axios
          .get(process.env.VUE_APP_SERVER_URL + "/auth/reset_password", {
            params: {
              email: email,
            },
          })
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            dispatch(
              "notifications/addNotification",
              {
                message: err.response.data.error,
                type: "error",
              },
              { root: true }
            );
            reject(err);
          });
      });
    },
    passwordNew({ dispatch }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(process.env.VUE_APP_SERVER_URL + "/auth/reset_password", {
            email: payload.email,
            token: payload.token,
            password: payload.password,
          })
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            dispatch(
              "notifications/addNotification",
              {
                message: err.response.data.error,
                type: "error",
              },
              { root: true }
            );
            reject(err);
          });
      });
    },
    setLanguage({ commit, dispatch }, language) {
      return new Promise((resolve, reject) => {
        axios
          .patch(process.env.VUE_APP_SERVER_URL + "/user", {
            profile: {
              settings: {
                language: language,
              },
            },
          })
          .then(() => {
            dispatch(
              "notifications/addNotification",
              {
                message: "notifications.success.profile",
                type: "success",
              },
              { root: true }
            );
            localStorage.setItem("language", language);
            commit("SET_LANGUAGE", language);
            resolve();
          })
          .catch((err) => {
            dispatch(
              "notifications/addNotification",
              {
                message: err.response.data.error,
                type: "error",
              },
              { root: true }
            );
            reject(err);
            console.log(err);
          });
      });
    },
    clear({ commit, dispatch }) {
      commit("LOGOUT");
      dispatch("car/clear", null, { root: true });
      dispatch("fluids/clear", null, { root: true });
    },
    setReferral({ commit }, referral) {
      commit("SET_REFERRAL", referral);
    },
  },
};
