export const coreRoutes = [
  {
    path: "/",
    name: "Dashboard",
    component: () => import("./views/mvp/CoreDashboard.vue"),
    meta: { requiresAuth: false },
  },
  {
    path: "/start",
    name: "FirstStart",
    component: () => import("./views/Start.vue"),
    meta: { requiresAuth: false, navbar: false },
  },
  {
    path: "/generic-settings",
    name: "GenericSettings",
    component: () => import("./views/GenericSettings.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/profile-settings",
    name: "ProfileSettings",
    component: () => import("./views/ProfileSettings.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/settings",
    name: "Settings",
    component: () => import("./views/mvp/Settings.vue"),
  },
  {
    path: "/onboarding",
    name: "Onboarding",
    component: () => import("./views/Onboarding.vue"),
    meta: { navbar: false },
  },
  {
    path: "/onboarding/:type",
    name: "OnboardingTyped",
    component: () => import("./views/Onboarding.vue"),
    meta: { navbar: false },
  },
  {
    path: "/wearparts-overview",
    name: "WearpartsOverview",
    component: () => import("./views/WearpartsOverview.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/feedback",
    name: "Feedback",
    component: () => import("./views/Feedback.vue"),
    meta: { requiresAuth: false },
  },
];
