<template>
  <button :class="classes" :type="type" :disabled="disabled" @click="animate">
    <slot />
  </button>
</template>

<script>
export const buttonType = {
  SUBMIT: "submit",
  BUTTON: "button",
};

export const buttonTheme = {
  PRIMARY: "primary",
  SECONDARY: "secondary",
};

export const buttonSize = {
  SMALL: "small",
  LIGHT: "light",
  MEDIUM: "medium",
  SEMI: "semi",
  BIG: "big",
  XL: "xl",
};

export default {
  props: {
    type: {
      type: String,
      default: buttonType.BUTTON,
      validator: (value) => Object.values(buttonType).includes(value),
    },
    theme: {
      type: String,
      default: buttonTheme.PRIMARY,
      validator: (value) => Object.values(buttonTheme).includes(value),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: buttonSize.BIG,
      validator: (value) => Object.values(buttonSize).includes(value),
    },
  },
  data() {
    return {
      btnClasses: ["btn", `btn--${this.theme}`, `btn--${this.size}`],
    };
  },
  computed: {
    classes() {
      return this.btnClasses;
    },
  },
  methods: {
    animate() {
      this.btnClasses.push("animate__pulse");
      setTimeout(
        (() => {
          this.btnClasses = this.btnClasses.filter((e) => {
            return e != "animate__pulse";
          });
        }).bind(this),
        500
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.btn {
  @apply inline-flex justify-center items-center border border-transparent font-medium rounded-xl shadow-sm focus:outline-none;
  outline: none;

  &:focus {
    outline: none;
  }

  &--xl {
    @apply px-6 py-3 text-base;
  }

  &--big {
    @apply px-6 py-2 text-base;
  }

  &--semi {
    @apply px-4 py-2 text-base;
  }

  &--medium {
    @apply px-4 py-2 text-sm;
  }

  &--light {
    @apply px-3 py-2 text-sm;
  }

  &--small {
    @apply px-2.5 py-1.5 rounded text-xs;
  }

  &--primary {
    @apply bg-vi-primary text-white;
  }

  &--secondary {
    @apply text-vi-primary border-vi-primary bg-white;
  }

  &:disabled {
    opacity: 0.6;
  }

  &.animate__pulse {
    animation-name: pulse;
    animation-timing-function: ease-in-out;
    animation-duration: 0.5s;
  }

  @keyframes pulse {
    from {
      transform: scale3d(1, 1, 1);
    }

    50% {
      transform: scale3d(0.95, 0.95, 0.95);
    }

    to {
      transform: scale3d(1, 1, 1);
    }
  }
}
</style>
